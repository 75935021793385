export default function withTheme(props) {
  //const dotNotationPattern = /^[^()]*\.[^()]*$/;
  const dotNotationPattern = /^(?![^()]*\d)[^()]*\.[^()]*$/;

  if (!props.config) return {};
  return Object.keys(props.config).reduce((acc, key) => {
    return {
      ...acc,
      [key]:
        typeof props.config[key] === "string" &&
        dotNotationPattern.test(props.config[key])
          ? props.config[key]
              .split(".")
              .reduce((acc, key) => acc[key], props.theme)
          : props.config[key],
    };
  }, {});
}
