import { useContext, useRef } from "react";
import { useSSRLayoutEffect } from "@oskarengstrom/sage-ui";
import { NavigationContext } from "@/contexts/NavigationContext";
import { gsap } from "gsap";

const useHoverAnimation = ({
  scopeRef,
  section_id,
  disabled,
  setupCustomAnimation,
}) => {
  const tl = useRef(gsap.timeline({ paused: true }));
  const { status } = useContext(NavigationContext);

  useSSRLayoutEffect(() => {
    if (
      scopeRef.current &&
      tl.current &&
      !disabled &&
      status !== "transitioning"
    ) {
      const ctx = gsap.context(() => {
        tl.current.to(
          `#${section_id}_main-blob`,
          {
            duration: 0.5,
            "--scale": "+=.10",
            ease: "power2.inOut",
          },
          0
        );

        tl.current.to(
          `#${section_id}_text_plus`,
          {
            autoAlpha: 0,
            duration: 0.2,
            ease: "power2.inOut",
          },
          0
        );

        if (setupCustomAnimation) {
          setupCustomAnimation(tl.current, { section_id });
        }
      }, scopeRef);

      return () => {
        ctx.revert();
      };
    }
  }, [scopeRef.current, tl.current, disabled, setupCustomAnimation, status]);

  return {
    onMouseEnter: () => tl.current?.timeScale(1).play(),
    onMouseLeave: () => tl.current?.timeScale(2).reverse(),
  };
};

export default useHoverAnimation;
