import getSizeFromPath from "@/utils/getSizeFromPath";
import { mq } from "@oskarengstrom/sage-ui";
import { blobX, blobY } from "@/utils/blobPosition";

function generateStyles(scope) {
  const { blobs, containerWidth, containerHeight, theme } = scope;

  const boxMobile = {
    x: containerWidth * 0.5 + 240 + "px",
    y: containerHeight * 0.5 - 20 + "px",
  };

  const box = {
    x: containerWidth * 0.5 + 420 + "px",
    y: containerHeight * 0.5 + 40 + "px",
  };

  const X = blobX(box.x);
  const Y = blobY(box.y);
  const XMobile = blobX(boxMobile.x);
  const YMobile = blobY(boxMobile.y);
  const mainBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "main-blob").path_data
  );
  const imageBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "image-blob").path_data
  );

  return {
    "main-blob": {
      "--x": X("-1rem"),
      "--y": Y("-07rem"),
      "--scale": 1,
      //transformOrigin: "437px 262px",
      transformOrigin: `${mainBlobSize.width / 2}px ${
        mainBlobSize.height / 2
      }px`,
      background: theme.palette.primary.lighter,
      [mq.only.touchDevice]: {
        transformOrigin: `${(mainBlobSize.width * 0.6) / 2}px ${
          (mainBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-5rem"),
        "--y": YMobile("-09rem"),
      },
    },
    "image-blob": {
      "--x": X("6rem"),
      "--y": Y("-07rem"),
      "--scale": 1,
      //transformOrigin: "199px 148px",
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.light,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("4rem"),
        "--y": YMobile("-10rem"),
      },
    },
    "image-blob-behind": {
      "--x": X("6rem"),
      "--y": Y("-07rem"),
      "--scale": 1,
      //transformOrigin: "199px 148px",
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.main,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("4rem"),
        "--y": YMobile("-10rem"),
      },
    },
    glasses: {
      top: box.y,
      left: box.x,
      transform: "translate(07rem, -08rem)",
      width: "391px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(05rem, -10rem)",
        width: "240px",
      },
    },
    woman: {
      top: box.y,
      left: box.x,
      transform: "translate(05rem, -08rem)",
      width: "629px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(04rem, -08rem)",
        width: "470px",
      },
    },
    "woman-behind": {
      top: box.y,
      left: box.x,
      transform: "translate(05rem, -08rem)",
      width: "629px",
      clipPath: "inset(0% 0% 45% 0%)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(04rem, -08rem)",
        width: "470px",
        clipPath: "inset(0% 0% 65% 0%)",
      },
    },
    "mobile-ref": {
      top: boxMobile.y,
      left: boxMobile.x,
      width: "518px",
      transform: "translate(1rem, -9rem)",
    },
    "text-block": {
      top: box.y,
      left: box.x,
      transform: "translate(07rem, 13rem)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(06rem, 04rem)",
        zIndex: 100,
        pointerEvents: "auto",
      },
    },
  };
}

function setupHoverAnimation(tl, scope) {
  const { section_id } = scope;

  tl.to(
    [`#${section_id}_image_woman`, `#${section_id}_image_woman-behind`],
    {
      duration: 1,
      scale: 1.08,
      ease: "power2.inOut",
    },
    0
  );
}

function setupTransitionOutAnimation(tl, scope) {
  const { section_id } = scope;

  tl.set(
    [`#${section_id}_clipped_main-blob`, `#${section_id}_text`],
    {
      zIndex: 100,
    },
    0
  );

  tl.to(
    `#${section_id}_main-blob`,
    {
      scale: "+=8",
      duration: 1,
      transformOrigin: "437px 262px",
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_image-blob`, `#${section_id}_image-blob-behind`],
    {
      duration: 0.5,
      scale: 0,
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_image_woman`, `#${section_id}_image_woman-behind`],
    {
      duration: 0.5,
      autoAlpha: 0,
      ease: "power3.inOut",
    },
    0
  );
}

export default {
  generateStyles,
  setupTransitionOutAnimation,
  setupHoverAnimation,
};
