import getSizeFromPath from "@/utils/getSizeFromPath";
import { mq } from "@oskarengstrom/sage-ui";
import { blobX, blobY } from "@/utils/blobPosition";
function generateStyles(scope) {
  const { blobs, containerWidth, containerHeight, theme } = scope;

  const boxMobile = {
    x: containerWidth * 0.5 - 40 + "px",
    y: containerHeight * 0.5 + 170 + "px",
  };

  const box = {
    x: containerWidth * 0.5 - 60 + "px",
    y: containerHeight * 0.5 + -30 + "px",
  };

  const X = blobX(box.x);
  const Y = blobY(box.y);
  const XMobile = blobX(boxMobile.x);
  const YMobile = blobY(boxMobile.y);
  const mainBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "main-blob").path_data
  );
  const imageBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "image-blob").path_data
  );

  return {
    "main-blob": {
      "--x": X("-25rem"),
      "--y": Y("-51rem"),
      "--scale": 0.94,
      transformOrigin: `${mainBlobSize.width / 2}px ${
        mainBlobSize.height / 2
      }px`,
      background: theme.palette.primary.lighter,
      [mq.only.touchDevice]: {
        transformOrigin: `${(mainBlobSize.width * 0.6) / 2}px ${
          (mainBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-25rem"),
        "--y": YMobile("-51rem"),
      },
    },
    "image-blob": {
      "--x": X("-3rem"),
      "--y": Y("-40rem"),
      "--scale": 0.94,
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.main,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-08rem"),
        "--y": YMobile("-43rem"),
      },
    },
    "image-blob-behind": {
      "--x": X("-3rem"),
      "--y": Y("-40rem"),
      "--scale": 0.94,
      //transformOrigin: "196px 194px",
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.light,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-08rem"),
        "--y": YMobile("-43rem"),
      },
    },
    "crown-behind": {
      top: box.y,
      left: box.x,
      width: "696px",
      transform: "translate(-11rem,-45rem) rotate(-22.95deg)",
      clipPath: "inset(0% 0% 0% 20%)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "500px",
        transform: "translate(-14rem,-44rem) rotate(-22.95deg)",
        clipPath: "inset(0% 0% 0% 25%)",
      },
    },
    crown: {
      top: box.y,
      left: box.x,
      width: "696px",
      transform: "translate(-11rem,-45rem) rotate(-22.95deg)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "500px",
        transform: "translate(-14rem,-44rem) rotate(-22.95deg)",
      },
    },
    "mobile-ref": {
      top: boxMobile.y,
      left: boxMobile.x,
      width: "600px",
      transform: "translate(-20rem,-51rem)",
    },
    "text-block": {
      top: box.y,
      left: box.x,
      transform: "translate(-18rem,-32rem)",
      maxWidth: "15rem",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-18rem,-38rem)",
        maxWidth: "15rem",
        zIndex: 100,
        pointerEvents: "auto",
      },
    },
    heart: {
      top: box.y,
      left: box.x,
      transform: "translate(-22rem,-64rem)",
      width: "500px",
      height: "500px",
      //display: "none",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "280px",
        height: "280px",
        transform: "translate(-19rem,-56rem)",
        display: "none",
      },
    },
  };
}

function setupHoverAnimation(tl, scope) {
  const { section_id } = scope;

  tl.to(
    [`#${section_id}_image-blob`, `#${section_id}_image-blob-behind`],
    {
      duration: 1,
      "--scale": "+=.1",
      "--x": "+=20",
      ease: "power2.inOut",
    },
    0
  );
}

function setupTransitionOutAnimation(tl, scope) {
  const { section_id } = scope;

  tl.set(
    [`#${section_id}_clipped_main-blob`, `#${section_id}_text`],
    {
      zIndex: 100,
    },
    0
  );

  tl.to(
    `#${section_id}_main-blob`,
    {
      scale: "+=5",
      duration: 1,
      transformOrigin: "437px 262px",
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_image-blob`, `#${section_id}_image-blob-behind`],
    {
      duration: 0.5,
      scale: 0,
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_image_crown`, `#${section_id}_image_crown-behind`],
    {
      duration: 0.5,
      autoAlpha: 0,
      ease: "power3.inOut",
    },
    0
  );
}

export default {
  generateStyles,
  setupHoverAnimation,
  setupTransitionOutAnimation,
};
