import { Section, Box, Typography as T, mq } from "@oskarengstrom/sage-ui";
import { linkResolver } from "@/prismic-configuration";
import styled from "@emotion/styled";
import { PrismicNextImage } from "@prismicio/next";
import { ThemeContext, useTheme, css } from "@emotion/react";
import IconButton from "@/components/ui/IconButton";
import { useTransition } from "@/hooks/Transition";

const MobileTemplate = ({ slice }) => {
  const theme = useTheme(ThemeContext);
  const { navigate } = useTransition();
  const { section_id, link, title, description } = slice.primary;
  const bgImage = slice.items.find(
    (item) => item.asset_type === "image" && item.name === "mobile-ref"
  );

  return (
    <Section
      css={css`
        background-color: transparent;
      `}
    >
      <Box
        position="relative"
        css={css`
          max-width: 50rem;
          margin: 0 auto;
        `}
        id={`${section_id}_text`}
        onClick={() => {
          navigate(linkResolver(link), theme.palette.primary.lighter);
        }}
      >
        <TextBlock>
          <T id={`${section_id}_text-title`} variant={"heading3"} as="h3">
            {title}
          </T>
          <T
            id={`${section_id}_text-description`}
            mt={[0.5, 1, 1]}
            mb={[0.5, 1, 2]}
          >
            {description}
          </T>
          <IconButton
            size="2.25rem"
            icon="plus"
            id={`${section_id}_text_plus`}
          />
        </TextBlock>
        <Image field={bgImage.image} />
      </Box>
    </Section>
  );
};

const TextBlock = styled.div`
  position: absolute;
  margin-top: 3.5rem;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;

  ${mq.up.md} {
    margin-top: 5rem;
  }
`;

const Image = styled(PrismicNextImage)`
  position: relative;
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export default MobileTemplate;
