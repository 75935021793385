import { useEffect, useRef, useContext, useState } from "react";
import { Typography as T, mq } from "@oskarengstrom/sage-ui";
import useIsTouchDevice from "@/hooks/useIsTouchDevice";
import styled from "@emotion/styled";
import { NavigationContext } from "@/contexts/NavigationContext";
import { linkResolver } from "@/prismic-configuration";
import { useRouter } from "next/router";
import { css } from "@emotion/react";
import IconButton from "@/components/ui/IconButton";
import RenderLayer from "@/components/RenderLayer";
import withTheme from "@/utils/withTheme";
import useOnLoadAnimation from "./useOnLoadAnimation";
import useHoverAnimation from "./useHoverAnimation";
import useTransitionOutAnimation from "./useTransitionOutAnimation";
import { ThemeContext, useTheme } from "@emotion/react";
import { useSetRecoilState } from "recoil";
import {
  idlePointerState,
  pointerState,
} from "@/contexts/pointer/pointerState";
import getProps from "./getProps";
import { useTransition } from "@/hooks/Transition";

const Template = ({ slice, tree }) => {
  const isTouchDevice = useIsTouchDevice();
  const { title, description, section_id, link } = slice.primary;
  const theme = useTheme(ThemeContext);
  const ref = useRef();
  const scopeRef = useRef();
  const router = useRouter();
  const { navigate } = useTransition();
  const props = getProps(section_id);
  const blobs = slice.items.filter((it) => it.asset_type === "blob");
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const setPointer = useSetRecoilState(pointerState);
  const { isMounting } = useOnLoadAnimation({
    scopeRef,
    section_id,
    disabled: false,
  });
  const { onMouseEnter, onMouseLeave } = useHoverAnimation({
    scopeRef,
    section_id,
    setupCustomAnimation: props.setupHoverAnimation,
    disabled: isTouchDevice || isMounting,
  });

  const {} = useTransitionOutAnimation({
    scopeRef,
    section_id,
    onComplete: () => router.push(linkResolver(link)),
    setupCustomAnimation: props.setupTransitionOutAnimation,
    disabled: isTouchDevice,
  });

  const setIdlePointerState = useSetRecoilState(idlePointerState);

  useEffect(() => {
    setIdlePointerState({ type: "idle" });
  }, []);

  const { onTransition } = useContext(NavigationContext);

  useEffect(() => {
    function onResize() {
      setContainerWidth(ref.current.offsetWidth);
      setContainerHeight(ref.current.offsetHeight);
    }
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  let config_ = {};
  if (typeof props.generateStyles === "function") {
    config_ = props.generateStyles({
      theme,
      containerWidth,
      containerHeight,
      blobs,
    });
  }

  useEffect(() => {
    router.prefetch(linkResolver(link));
  }, [link, router]);

  return (
    <div
      className={section_id}
      ref={scopeRef}
      css={css`
        display: contents;
      `}
    >
      {!isTouchDevice && (
        <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
          <defs>
            {slice.items
              .filter((item) => item.asset_type === "blob")
              .map((item) => (
                <ClipPath
                  key={item.name}
                  id={`${section_id}_${item.name}`}
                  config={config_[item.name]}
                >
                  <path d={item.path_data} />
                </ClipPath>
              ))}
          </defs>
        </svg>
      )}

      <RenderLayer
        tree={tree}
        layer="back"
        section_id={section_id}
        config={config_}
      />
      <RenderLayer
        tree={tree}
        layer="middle"
        section_id={section_id}
        config={config_}
        onMouseEnter={() => {
          setPointer({ type: "canvas", color: theme.palette.primary.lighter });
          onMouseEnter();
        }}
        onMouseLeave={() => {
          setPointer(false);
          onMouseLeave();
        }}
        onClick={() => {
          if (!isTouchDevice) {
            setPointer(false);
            onTransition(section_id, theme.palette.primary.lighter);
          }
        }}
      />
      <RenderLayer
        tree={tree}
        layer="front"
        section_id={section_id}
        config={config_}
      >
        <TextBlock
          id={`${section_id}_text`}
          config={config_["text-block"]}
          onClick={() => {
            if (isTouchDevice) {
              navigate(linkResolver(link), theme.palette.primary.lighter);
            }
          }}
        >
          <T
            id={`${section_id}_text-title`}
            variant={
              section_id === "forord" || section_id === "slutsatsen"
                ? "labelLarge"
                : "heading3"
            }
            as="h3"
          >
            {title}
          </T>
          <T
            id={`${section_id}_text-description`}
            mt={[0.5, 1, 1]}
            mb={[0.5, 1, 2]}
          >
            {description}
          </T>
          <IconButton
            size="2.25rem"
            icon="plus"
            id={`${section_id}_text_plus`}
          />
        </TextBlock>
        <div
          ref={ref}
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
          `}
        />
      </RenderLayer>
    </div>
  );
};

const TextBlock = styled.div`
  ${(props) => withTheme(props)};
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
`;

const ClipPath = styled.clipPath`
  --x: 0px;
  --y: 0px;
  --scale: 1;
  ${(props) => ({ ...props.config })};
  will-change: transform;
  transform: translate(var(--x), var(--y)) scale(var(--scale));
  ${mq.only.touchDevice} {
    will-change: unset;
  }
`;

export default Template;
