import { mq } from "@oskarengstrom/sage-ui";
import { blobX, blobY } from "@/utils/blobPosition";
function generateStyles(scope) {
  const { containerWidth, containerHeight, theme } = scope;

  const boxMobile = {
    x: containerWidth * 0.5 - 560 + "px",
    y: containerHeight * 0.5 - 220 + "px",
  };

  const box = {
    x: containerWidth * 0.5 - 900 + "px",
    y: containerHeight * 0.5 - 360 + "px",
  };

  const X = blobX(box.x);
  const Y = blobY(box.y);
  const XMobile = blobX(boxMobile.x);
  const YMobile = blobY(boxMobile.y);

  return {
    "main-blob": {
      "--x": X("-20rem"),
      "--y": Y("-20rem"),
      "--scale": 1,
      transformOrigin: "251px 168px",
      background: theme.palette.primary.light,
      [mq.only.touchDevice]: {
        "--scale": 0.8,
        "--x": XMobile("-20rem"),
        "--y": YMobile("-20rem"),
      },
    },
    "side-blob": {
      "--x": X("-0rem"),
      "--y": Y("-17rem"),
      "--scale": 1,
      transformOrigin: "149px 83px",
      background: theme.palette.background,
      [mq.only.touchDevice]: {
        "--scale": 0.6,
        "--x": XMobile("-2rem"),
        "--y": YMobile("-17rem"),
      },
    },
    "side-blob-behind": {
      "--x": X("-0rem"),
      "--y": Y("-17rem"),
      "--scale": 1,
      transformOrigin: "149px 83px",
      background: theme.palette.primary.light,
      [mq.only.touchDevice]: {
        "--scale": 0.6,
        "--x": XMobile("-2rem"),
        "--y": YMobile("-17rem"),
      },
    },
    "bulb-off": {
      top: box.y,
      left: box.x,
      transform: "translate(00rem,-18rem)",
      width: "300px",
      zIndex: 5,
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-2rem,-19rem)",
        width: "200px",
      },
    },
    "bulb-on": {
      top: box.y,
      left: box.x,
      transform: "translate(00rem,-18rem)",
      width: "300px",
      opacity: 0,
      visibility: "hidden",
      [mq.only.touchDevice]: {},
    },
    swimmer: {
      top: box.y,
      left: box.x,
      transform: "translate(-20rem,-36rem)",
      width: "380px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-21rem,-26rem)",
        width: "200px",
      },
    },
    "mobile-ref": {
      top: boxMobile.y,
      left: boxMobile.x,
      width: "540px",
      transform: "translate(-21rem, -35rem)",
    },
    "text-block": {
      top: box.y,
      left: box.x,
      transform: "translate(-11rem,-14rem)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-12rem,-16rem)",
        zIndex: 100,
        pointerEvents: "auto",
      },
    },
  };
}

function setupHoverAnimation(tl, scope) {
  const { section_id } = scope;

  tl.to(
    `#${section_id}_image_bulb-off`,
    {
      autoAlpha: 0,
      duration: 0.3,
    },
    0
  );
  tl.to(
    `#${section_id}_image_bulb-on`,
    {
      autoAlpha: 1,
      duration: 0.3,
    },
    0
  );
}

function setupTransitionOutAnimation(tl, scope) {
  const { section_id } = scope;

  tl.set(
    [`#${section_id}_clipped_main-blob`, `#${section_id}_text`],
    {
      zIndex: 100,
    },
    0
  );

  tl.to(
    `#${section_id}_main-blob`,
    {
      scale: "+=14",
      duration: 1.1,
      transformOrigin: "200px 100px",
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_side-blob`, `#${section_id}_side-blob-behind`],
    {
      duration: 0.5,
      scale: 0,
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [
      `#${section_id}_image_swimmer`,
      `#${section_id}_image_bulb-on`,
      `#${section_id}_image_bulb-off`,
    ],
    {
      duration: 0.5,
      autoAlpha: 0,
      ease: "power3.inOut",
    },
    0
  );
}

export default {
  generateStyles,
  setupHoverAnimation,
  setupTransitionOutAnimation,
};
