import getSizeFromPath from "@/utils/getSizeFromPath";
import { mq } from "@oskarengstrom/sage-ui";
import { blobX, blobY } from "@/utils/blobPosition";
function generateStyles(scope) {
  const { blobs, containerWidth, containerHeight, theme } = scope;

  const boxMobile = {
    x: containerWidth * 0.5 + 100 + "px",
    y: containerHeight * 0.5 + 280 + "px",
  };

  const box = {
    x: containerWidth * 0.5 - 40 + "px",
    y: containerHeight * 0.5 + 450 + "px",
  };

  const X = blobX(box.x);
  const Y = blobY(box.y);
  const XMobile = blobX(boxMobile.x);
  const YMobile = blobY(boxMobile.y);
  const mainBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "main-blob").path_data
  );
  const imageBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "image-blob").path_data
  );

  return {
    "main-blob": {
      "--x": X("-18rem"),
      "--y": Y("-08rem"),
      "--scale": 1,
      //transformOrigin: "429px 274px",
      transformOrigin: `${mainBlobSize.width / 2}px ${
        mainBlobSize.height / 2
      }px`,
      background: theme.palette.primary.lighter,
      [mq.only.touchDevice]: {
        transformOrigin: `${(mainBlobSize.width * 0.6) / 2}px ${
          (mainBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-24rem"),
        "--y": YMobile("-11rem"),
      },
    },
    "image-blob": {
      "--x": X("-11rem"),
      "--y": Y("-08rem"),
      "--scale": 1,
      //transformOrigin: "231px 146px",
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.light,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-15rem"),
        "--y": YMobile("-11rem"),
      },
    },
    "image-blob-behind": {
      "--x": X("-11rem"),
      "--y": Y("-08rem"),
      "--scale": 1,
      //transformOrigin: "231px 146px",
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.main,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-15rem"),
        "--y": YMobile("-11rem"),
      },
    },
    man: {
      top: box.y,
      left: box.x,
      transform: "translate(-16rem, -05rem)",
      width: "640px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-17rem, -120px)",
        width: "540px",
      },
    },
    flowers: {
      top: box.y,
      left: box.x,
      transform: "translate(12rem, 04rem)",
      width: "450px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(02rem, 00rem)",
        width: "260px",
      },
    },
    bee: {
      top: box.y,
      left: box.x,
      transform: "translate(24rem, 00rem)",
      width: "49px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(07rem, -3rem)",
        width: "30px",
      },
    },
    "mobile-ref": {
      top: boxMobile.y,
      left: boxMobile.x,
      width: "520px",
      transform: "translate(-15rem, -8rem)",
    },
    "text-block": {
      top: box.y,
      left: box.x,
      transform: "translate(-04rem, 13rem)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-08rem, 03rem)",
        zIndex: 100,
        pointerEvents: "auto",
      },
    },
  };
}

function setupHoverAnimation(tl, scope) {
  const { section_id } = scope;

  tl.to(
    `#${section_id}_image_bee`,
    {
      y: "+=100px",
      x: "-=40px",
      rotate: "-=8deg",
      duration: 1,
      ease: "power2.inOut",
    },
    "-=0.4"
  );
  tl.to(
    `#${section_id}_image_flowers`,
    {
      scale: "+=0.1",
      duration: 1,
      ease: "power2.inOut",
    },
    0
  );
}

function setupTransitionOutAnimation(tl, scope) {
  const { section_id } = scope;

  tl.set(
    [`#${section_id}_clipped_main-blob`, `#${section_id}_text`],
    {
      zIndex: 100,
    },
    0
  );

  tl.to(
    `#${section_id}_main-blob`,
    {
      scale: "+=10",
      duration: 1.2,
      transformOrigin: "300px 400px",
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_image-blob`, `#${section_id}_image-blob-behind`],
    {
      duration: 0.5,
      scale: 0,
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [
      `#${section_id}_image_man`,
      `#${section_id}_image_flowers`,
      `#${section_id}_image_bee`,
    ],
    {
      duration: 0.5,
      autoAlpha: 0,
      ease: "power3.inOut",
    },
    0
  );
}

export default {
  generateStyles,
  setupHoverAnimation,
  setupTransitionOutAnimation,
};
