import { useContext, useRef, useEffect, useState } from "react";
import { NavigationContext } from "@/contexts/NavigationContext";
import { useSSRLayoutEffect } from "@oskarengstrom/sage-ui";
import { gsap } from "gsap";

const useTransitionOutAnimation = ({
  scopeRef,
  section_id,
  onComplete,
  setupCustomAnimation,
}) => {
  const [complete, setComplete] = useState(false);
  const { status, activeSectionId } = useContext(NavigationContext);
  const tl = useRef(
    gsap.timeline({
      paused: true,
    })
  );

  const tlActive = useRef(
    gsap.timeline({
      paused: true,
      onComplete: () => {
        setComplete(true);
      },
    })
  );

  function AnimateTitle(node) {
    const scale = 2.0;
    const { left, width, top, height } = node.getBoundingClientRect();
    var offsetLeft = left + width / 2 - window.innerWidth / 2;
    var offsetTop = top + height / 2 - window.innerHeight / 2;

    gsap.to(node, {
      x: "-=" + offsetLeft,
      y: "-=" + offsetTop,
      scale,
      duration: 1,
      ease: "power3.inOut",
    });
  }

  useSSRLayoutEffect(() => {
    if (scopeRef.current && tl.current && tlActive.current) {
      const ctx = gsap.context(() => {
        // setup active animation

        tlActive.current.to(
          [`#${section_id}_text-description`],
          {
            duration: 0.5,
            autoAlpha: 0,
          },
          0
        );
        tlActive.current.to(
          `#${section_id}_text_plus`,
          {
            scale: 0,
            duration: 0.5,
          },
          0.25
        );

        if (setupCustomAnimation) {
          setupCustomAnimation(tlActive.current, { section_id });
        }

        tlActive.current.add(
          () =>
            AnimateTitle(
              scopeRef.current.querySelector(`#${section_id}_text-title`)
            ),
          0
        );

        // setup inactive animation
        //tl.current.to(`[id ^= "${section_id}"]`, {
        //  autoAlpha: 0,
        //  duration: 0.1,
        //});
      }, scopeRef);

      return () => {
        ctx.revert();
      };
    }
  }, [scopeRef.current, tl.current, tlActive.current, setupCustomAnimation]);

  useEffect(() => {
    if (status === "transitioning" && tl.current && tlActive.current) {
      if (activeSectionId === section_id) {
        tlActive.current.play();
      } else {
        tl.current.play();
      }
    }
  }, [status, tl.current, tlActive.current, activeSectionId, section_id]);

  useEffect(() => {
    if (complete && onComplete && activeSectionId === section_id) {
      onComplete();
    }
  }, [complete, onComplete, activeSectionId, section_id]);

  return {};
};

export default useTransitionOutAnimation;
