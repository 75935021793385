import getSizeFromPath from "@/utils/getSizeFromPath";
import { mq } from "@oskarengstrom/sage-ui";
import { blobX, blobY } from "@/utils/blobPosition";

function generateStyles(scope) {
  const { blobs, containerWidth, containerHeight, theme } = scope;

  const boxMobile = {
    x: containerWidth * 0.5 + "px",
    y: containerHeight * 0.5 + 200 + "px",
  };

  const box = {
    x: containerWidth * 0.5 - 570 + "px",
    y: containerHeight * 0.5 + 400 + "px",
  };

  const X = blobX(box.x);
  const Y = blobY(box.y);
  const XMobile = blobX(boxMobile.x);
  const YMobile = blobY(boxMobile.y);
  const mainBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "main-blob").path_data
  );
  const imageBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "image-blob").path_data
  );

  return {
    "main-blob": {
      "--x": X("-42rem"),
      "--y": Y("-06rem"),
      "--scale": 1,
      //transformOrigin: "383px 283px",
      transformOrigin: `${mainBlobSize.width / 2}px ${
        mainBlobSize.height / 2
      }px`,
      background: theme.palette.primary.lighter,
      [mq.only.touchDevice]: {
        transformOrigin: `${(mainBlobSize.width * 0.6) / 2}px ${
          (mainBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-48rem"),
        "--y": YMobile("-10rem"),
      },
    },
    "image-blob": {
      "--x": X("-45rem"),
      "--y": Y("12rem"),
      "--scale": 1,
      //transformOrigin: "225px 133px",
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.light,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-48rem"),
        "--y": YMobile("05rem"),
      },
    },
    "image-blob-behind": {
      "--x": X("-45rem"),
      "--y": Y("12rem"),
      "--scale": 1,
      //transformOrigin: "225px 133px",
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.main,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-48rem"),
        "--y": YMobile("05rem"),
      },
    },
    "brain-processor": {
      top: box.y,
      left: box.x,
      transform: "translate(-43rem, 11rem)",
      width: "472px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-43rem, 05rem)",
        width: "300px",
      },
    },
    staty: {
      top: box.y,
      left: box.x,
      transform: "translate(-44rem, 03rem)",
      width: "975px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-44rem, 00rem)",
        width: "600px",
      },
    },
    "staty-behind": {
      top: box.y,
      left: box.x,
      transform: "translate(-44rem, 03rem)",
      width: "975px",
      clipPath: "inset(0% 0% 70% 0%)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-44rem, 00rem)",
        width: "600px",
        clipPath: "inset(0% 0% 70% 0%)",
      },
    },
    "mobile-ref": {
      top: boxMobile.y,
      left: boxMobile.x,
      width: "640px",
      transform: "translate(-44rem, -05rem)",
    },
    "text-block": {
      top: box.y,
      left: box.x,
      transform: "translate(-36rem, -00rem)",
      maxWidth: "15rem",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-40rem, -03rem)",
        maxWidth: "13rem",
        zIndex: 100,
        pointerEvents: "auto",
      },
    },
  };
}

function setupHoverAnimation(tl, scope) {
  const { section_id } = scope;

  tl.to(
    [`#${section_id}_image_staty`, `#${section_id}_image_staty-behind`],
    {
      duration: 1,
      scale: 1.05,
      y: "+=10",
      ease: "power2.inOut",
    },
    0
  );
}

function setupTransitionOutAnimation(tl, scope) {
  const { section_id } = scope;

  tl.set(
    [`#${section_id}_clipped_main-blob`, `#${section_id}_text`],
    {
      zIndex: 100,
    },
    0
  );

  tl.to(
    `#${section_id}_main-blob`,
    {
      scale: "+=10",
      duration: 1.2,
      transformOrigin: "100px 262px",
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_image-blob`, `#${section_id}_image-blob-behind`],
    {
      duration: 0.5,
      scale: 0,
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [
      `#${section_id}_image_staty`,
      `#${section_id}_image_staty-behind`,
      `#${section_id}_brain-processor`,
    ],
    {
      duration: 0.5,
      autoAlpha: 0,
      ease: "power3.inOut",
    },
    0
  );
}

export default {
  generateStyles,
  setupHoverAnimation,
  setupTransitionOutAnimation,
};
