import getSizeFromPath from "@/utils/getSizeFromPath";
import { mq } from "@oskarengstrom/sage-ui";
import { blobX, blobY } from "@/utils/blobPosition";

function generateStyles(scope) {
  const { blobs, containerWidth, containerHeight, theme } = scope;
  const boxMobile = {
    x: containerWidth * 0.5 + 160 + "px",
    y: containerHeight * 0.5 - 80 + "px",
  };

  const box = {
    x: containerWidth * 0.5 + 410 + "px",
    y: containerHeight * 0.5 - 300 + "px",
  };

  const X = blobX(box.x);
  const Y = blobY(box.y);
  const XMobile = blobX(boxMobile.x);
  const YMobile = blobY(boxMobile.y);
  const mainBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "main-blob").path_data
  );
  const imageBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "image-blob").path_data
  );

  return {
    "main-blob": {
      "--x": X("4rem"),
      "--y": Y("-24rem"),
      "--scale": 0.96,
      //transformOrigin: "388px 251px",
      transformOrigin: `${mainBlobSize.width / 2}px ${
        mainBlobSize.height / 2
      }px`,
      background: theme.palette.primary.lighter,
      [mq.only.touchDevice]: {
        transformOrigin: `${(mainBlobSize.width * 0.6) / 2}px ${
          (mainBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("2rem"),
        "--y": YMobile("-26rem"),
      },
    },
    "image-blob": {
      "--x": X("9rem"),
      "--y": Y("-26rem"),
      "--scale": 0.96,
      //transformOrigin: "224px 126px",
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.main,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("09rem"),
        "--y": YMobile("-28rem"),
      },
    },
    "image-blob-behind": {
      "--x": X("9rem"),
      "--y": Y("-26rem"),
      "--scale": 0.96,
      //transformOrigin: "224px 126px"
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.light,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("09rem"),
        "--y": YMobile("-28rem"),
      },
    },
    robot: {
      top: box.y,
      left: box.x,
      transform: "translate(10rem, -34rem)",
      width: "499px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(10rem, -34rem)",
        width: "380px",
      },
    },
    "robot-behind": {
      top: box.y,
      left: box.x,
      transform: "translate(10rem, -34rem)",
      width: "499px",

      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(10rem, -34rem)",
        width: "380px",
      },
    },
    mynt: {
      top: box.y,
      left: box.x,
      transform: "translate(1rem, -22rem)",
      width: "386px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(4rem, -22rem)",
        width: "240px",
      },
    },
    droppar: {
      top: box.y,
      left: box.x,
      transform: "translate(36rem, -32rem)",
      width: "227px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(29rem, -28rem)",
        width: "120px",
      },
    },
    "mobile-ref": {
      top: boxMobile.y,
      left: boxMobile.x,
      width: "518px",
      transform: "translate(4rem, -29rem)",
    },
    "text-block": {
      top: box.y,
      left: box.x,
      transform: "translate(20rem, -8rem)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(17rem, -15rem)",
        zIndex: 100,
        pointerEvents: "auto",
      },
    },
  };
}

function setupHoverAnimation(tl, scope) {
  const { section_id } = scope;

  tl.to(
    `#${section_id}_image_mynt`,
    {
      duration: 0.8,
      scale: 1.05,
      x: "-=10",
      ease: "power2.inOut",
    },
    0
  );

  tl.to(
    `#${section_id}_image_droppar`,
    {
      duration: 0.8,
      scale: 1.05,
      delay: 0.25,
      ease: "power2.inOut",
    },
    0
  );
}

function setupTransitionOutAnimation(tl, scope) {
  const { section_id } = scope;

  tl.set(
    [`#${section_id}_clipped_main-blob`, `#${section_id}_text`],
    {
      zIndex: 100,
    },
    0
  );

  tl.to(
    `#${section_id}_main-blob`,
    {
      scale: "+=5",
      duration: 1,
      transformOrigin: "437px 262px",
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_image-blob`, `#${section_id}_image-blob-behind`],
    {
      duration: 0.5,
      scale: 0,
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [
      `#${section_id}_image_robot`,
      `#${section_id}_image_robot-behind`,
      `#${section_id}_image_mynt`,
      `#${section_id}_image_droppar`,
    ],
    {
      duration: 0.5,
      autoAlpha: 0,
      ease: "power3.inOut",
    },
    0
  );
}

export default {
  generateStyles,
  setupHoverAnimation,
  setupTransitionOutAnimation,
};
