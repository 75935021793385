import samhallsansvar from "./configurations/samhallsansvar";
import hjarnan from "./configurations/hjarnan";
import framtidsscenarier from "./configurations/framtidsscenarier";
import beteenden from "./configurations/beteenden";
import teknikai from "./configurations/teknikai";
import marknadsplatser from "./configurations/marknadsplatser";
import forord from "./configurations/forord";
import slutsatsen from "./configurations/slutsatsen";

export default function getProps(section_id) {
  switch (section_id) {
    case "slutsatsen": {
      return slutsatsen;
    }
    case "forord": {
      return forord;
    }
    case "hjarnan": {
      return hjarnan;
    }
    case "samhallsansvar": {
      return samhallsansvar;
    }
    case "marknadsplatser": {
      return marknadsplatser;
    }
    case "beteenden": {
      return beteenden;
    }
    case "teknik-och-ai": {
      return teknikai;
    }
    case "framtidsscenarier": {
      return framtidsscenarier;
    }
    default: {
      return null;
    }
  }
}
