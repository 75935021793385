import { useContext, useRef, useState } from "react";
import { useSSRLayoutEffect } from "@oskarengstrom/sage-ui";
import { NavigationContext } from "@/contexts/NavigationContext";
import { gsap } from "gsap";

const useOnLoadAnimation = ({ scopeRef, section_id, disabled }) => {
  const [isMounting, setIsMounting] = useState(false);
  const tl = useRef(
    gsap.timeline({
      paused: true,
      onComplete: () => {
        setIsMounting(false);
      },
    })
  );
  const { status } = useContext(NavigationContext);

  useSSRLayoutEffect(() => {
    if (scopeRef.current && tl.current && !disabled) {
      // setup
      const ctx = gsap.context(() => {
        // random delay between .2 and .5
        const delay = Math.random() * 0.5 + 0.2;

        tl.current.from(
          `#${section_id}_main-blob`,
          {
            duration: 0.5,
            ease: "power3.out",
            "--scale": 0,
            delay,
          },
          0.1
        );
        tl.current.from(
          [
            `#${section_id}_image-blob`,
            `#${section_id}_image-blob-behind`,
            `#${section_id}_small-blob`,
            `#${section_id}_small-blob-behind`,
            `#${section_id}_side-blob`,
            `#${section_id}_side-blob-behind`,
          ],
          {
            "--scale": 0,
            duration: 0.9,
            ease: "power3.out",
            delay,
          },
          0.1
        );

        tl.current.from(
          [`[id*=image]`],
          {
            autoAlpha: 0,
            duration: 0.5,
            ease: "power3.out",
            delay,
          },
          0.5
        );

        tl.current.from(
          `#${section_id}_text`,
          {
            duration: 0.4,
            scale: "-=0.05",
            autoAlpha: 0,
          },
          "-=.3"
        );

        tl.current.timeScale(1);
      }, scopeRef);

      return () => {
        ctx.revert();
      };
    }
  }, [scopeRef.current, tl.current, disabled]);

  useSSRLayoutEffect(() => {
    if (tl.current && !disabled) {
      setIsMounting(true);
      tl.current.play();
    }
  }, [status, tl.current, disabled]);

  return { isMounting };
};

export default useOnLoadAnimation;
