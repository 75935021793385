import { SageProvider } from "@oskarengstrom/sage-ui";
import useIsTouchDevice from "@/hooks/useIsTouchDevice";
import { baseTheme, themes } from "@/styles/theme";
import renderLayoutTree from "@/utils/renderLayoutTree";
import Template from "./Template";
import MobileTemplate from "./MobileTemplate";

const CanvasSectionV2 = ({ slice }) => {
  const isTouchDevice = useIsTouchDevice();
  const { theme } = slice.primary;

  const images = slice.items.filter((item) => item.asset_type === "image");
  const blobs = slice.items.filter((item) => item.asset_type === "blob");
  const graphics = slice.items.filter((item) => item.asset_type === "graphic");
  const tree = renderLayoutTree(
    images,
    blobs,
    graphics,
    isTouchDevice ? "mobile" : "desktop"
  );

  // wait until we know if it's a touch device or not
  if (typeof isTouchDevice === "undefined") {
    return <div></div>;
  }

  if (isTouchDevice) {
    return (
      <SageProvider theme={themes[theme?.slug] || baseTheme}>
        <MobileTemplate slice={slice} />
      </SageProvider>
    );
  }

  return (
    <SageProvider theme={themes[theme?.slug] || baseTheme}>
      <Template tree={tree} slice={slice} />
    </SageProvider>
  );
};

export default CanvasSectionV2;
