import getSizeFromPath from "@/utils/getSizeFromPath";
import { mq } from "@oskarengstrom/sage-ui";
import { blobX, blobY } from "@/utils/blobPosition";

function generateStyles(scope) {
  const { blobs, containerWidth, containerHeight, theme } = scope;

  const boxMobile = {
    x: containerWidth * 0.5 + 340 + "px",
    y: containerHeight * 0.5 + "px",
  };

  const box = {
    x: containerWidth * 0.5 + "px",
    y: containerHeight * 0.5 + 30 + "px",
  };

  const X = blobX(box.x);
  const Y = blobY(box.y);

  const XMobile = blobX(boxMobile.x);
  const YMobile = blobY(boxMobile.y);

  const mainBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "main-blob").path_data
  );
  const imageBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "image-blob").path_data
  );

  return {
    "main-blob": {
      "--x": X("-68rem"),
      "--y": Y("-18rem"),
      "--scale": 1.01,
      transformOrigin: `${mainBlobSize.width / 2}px ${
        mainBlobSize.height / 2
      }px`,
      background: theme.palette.primary.lighter,
      [mq.only.touchDevice]: {
        transformOrigin: `${(mainBlobSize.width * 0.6) / 2}px ${
          (mainBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-74rem"),
        "--y": YMobile("-21rem"),
      },
    },
    "image-blob": {
      "--x": X("-44rem"),
      "--y": Y("0rem"),
      "--scale": 1.01,
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.main,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-58rem"),
        "--y": YMobile("-8rem"),
      },
    },
    "image-blob-behind": {
      "--x": X("-44rem"),
      "--y": Y("0rem"),
      "--scale": 1.01,
      //transformOrigin: "221px 134px",
      transformOrigin: `${imageBlobSize.width / 2}px ${
        imageBlobSize.height / 2
      }px`,
      background: theme.palette.primary.light,
      [mq.only.touchDevice]: {
        transformOrigin: `${(imageBlobSize.width * 0.6) / 2}px ${
          (imageBlobSize.height * 0.6) / 2
        }px`,
        "--scale": 0.6,
        "--x": XMobile("-58rem"),
        "--y": YMobile("-8rem"),
      },
    },
    earth: {
      top: box.y,
      left: box.x,
      transform: "translate(-80rem, -14rem)",
      width: "400px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "240px",
        transform: "translate(-75rem, -15rem)",
      },
    },
    sko: {
      top: box.y,
      left: box.x,
      width: "220px",
      transform: "translate(-64rem,-8rem) rotate(-60deg) !important",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "120px",
        transform: "translate(-65rem,-9rem) rotate(-60deg) !important",
      },
    },
    tag: {
      top: box.y,
      left: box.x,
      transform: "translate(-55rem, -9px)",
      width: "98px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "40px",
        transform: "translate(-952px, -80px)",
      },
    },
    thumb: {
      top: box.y,
      left: box.x,
      width: "87px",
      transform: "translate(-58rem, -12rem) rotate(30deg) !important",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "64px",
        transform: "translate(-62rem, -13rem) rotate(30deg) !important",
      },
    },
    space: {
      top: box.y,
      left: box.x,
      transform: "translate(-44rem, 1rem)",
      width: "555px",
      [mq.down.md({
        theme,
      })]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "300px",
        transform: "translate(-55rem, -5.5rem)",
      },
    },
    "mobile-ref": {
      top: boxMobile.y,
      left: boxMobile.x,
      width: "620px",
      transform: "translate(-75rem, -17rem)",
    },
    "text-block": {
      top: box.y,
      left: box.x,
      transform: "translate(-44rem, -11rem)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-55rem, -14rem)",
        zIndex: 100,
        pointerEvents: "auto",
      },
    },
  };
}

function setupHoverAnimation(tl, scope) {
  const { section_id } = scope;

  tl.to(
    `#${section_id}_image_earth`,
    {
      duration: 1.5,
      rotate: -30,
      scale: 1.1,
      ease: "power3.inOut",
    },
    0
  );
}

function setupTransitionOutAnimation(tl, scope) {
  const { section_id } = scope;

  tl.set(
    [`#${section_id}_clipped_main-blob`, `#${section_id}_text`],
    {
      zIndex: 100,
    },
    0
  );

  tl.to(
    `#${section_id}_main-blob`,
    {
      scale: "+=7",
      duration: 1.1,
      transformOrigin: "437px 262px",
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_image-blob`, `#${section_id}_image-blob-behind`],
    {
      duration: 0.5,
      scale: 0,
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [
      `#${section_id}_image_earth`,
      `#${section_id}_image_space`,
      `#${section_id}_image_sko`,
      `#${section_id}_image_thumb`,
    ],
    {
      duration: 0.5,
      autoAlpha: 0,
      ease: "power3.inOut",
    },
    0
  );
}

export default {
  generateStyles,
  setupHoverAnimation,
  setupTransitionOutAnimation,
};
