import { mq } from "@oskarengstrom/sage-ui";
import { blobX, blobY } from "@/utils/blobPosition";
import getSizeFromPath from "@/utils/getSizeFromPath";

function generateStyles(scope) {
  const { containerWidth, containerHeight, theme, blobs } = scope;

  const boxMobile = {
    x: containerWidth * 0.5 - 10 + "px",
    y: containerHeight * 0.5 + 20 + "px",
  };

  const box = {
    x: containerWidth * 0.5 + "px",
    y: containerHeight * 0.5 + "px",
  };

  const X = blobX(box.x);
  const Y = blobY(box.y);
  const XMobile = blobX(boxMobile.x);
  const YMobile = blobY(boxMobile.y);
  const mainBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "main-blob").path_data
  );
  const smallBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "small-blob").path_data
  );

  return {
    "main-blob": {
      "--x": X("-07rem"),
      "--y": Y("-15rem"),
      "--scale": 1.01,
      transformOrigin: `${mainBlobSize.width / 2}px ${
        mainBlobSize.height / 2
      }px`,
      background: theme.constants.colors.alpha.lightgrey,
      [mq.only.touchDevice]: {
        transformOrigin: `${(mainBlobSize.width * 0.7) / 2}px ${
          (mainBlobSize.height * 0.7) / 2
        }px`,
        "--scale": 0.7,
        "--x": XMobile("-15rem"),
        "--y": YMobile("-15rem"),
      },
    },
    "small-blob": {
      "--x": X("02rem"),
      "--y": Y("-15rem"),
      "--scale": 1.01,
      transformOrigin: `${smallBlobSize.width / 2}px ${
        smallBlobSize.height / 2
      }px`,
      background: theme.palette.background,
      [mq.only.touchDevice]: {
        transformOrigin: `${(smallBlobSize.width * 0.7) / 2}px ${
          (smallBlobSize.height * 0.7) / 2
        }px`,
        "--scale": 0.7,
        "--x": XMobile("-5rem"),
        "--y": YMobile("-14rem"),
      },
    },
    "small-blob-behind": {
      "--x": X("02rem"),
      "--y": Y("-15rem"),
      "--scale": 1.01,
      transformOrigin: `${smallBlobSize.width / 2}px ${
        smallBlobSize.height / 2
      }px`,
      background: theme.constants.colors.maroon.primary,
      [mq.only.touchDevice]: {
        transformOrigin: `${(smallBlobSize.width * 0.7) / 2}px ${
          (smallBlobSize.height * 0.7) / 2
        }px`,
        "--scale": 0.7,
        "--x": XMobile("-5rem"),
        "--y": YMobile("-14rem"),
      },
    },
    vd: {
      top: box.y,
      left: box.x,
      transform: "translate(03rem, -08rem)",
      width: "350px",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "220px",
        transform: "translate(-3rem, -06rem)",
      },
    },
    "vd-behind": {
      top: box.y,
      left: box.x,
      transform: "translate(03rem, -08rem)",
      width: "350px",
      clipPath: "inset(0% 0% 25% 0%)",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        width: "220px",
        transform: "translate(-3rem, -06rem)",
        clipPath: "inset(0% 0% 35% 0%)",
      },
    },
    "mobile-ref": {
      top: boxMobile.y,
      left: boxMobile.x,
      width: "420px",
      transform: "translate(-12rem, -16rem)",
    },
    "text-block": {
      top: box.y,
      left: box.x,
      transform: "translate(-05rem, -02rem)",
      maxWidth: "12rem",
      [mq.only.touchDevice]: {
        top: boxMobile.y,
        left: boxMobile.x,
        transform: "translate(-10rem, -05rem)",
        maxWidth: "10rem",
        zIndex: 100,
        pointerEvents: "auto",
      },
    },
  };
}

function setupHoverAnimation(tl, scope) {
  const { section_id } = scope;

  tl.to(
    [`#${section_id}_image_vd`, `#${section_id}_image_vd-behind`],
    {
      duration: 1,
      scale: 1.1,
      ease: "power2.inOut",
    },
    0
  );
}

function setupTransitionOutAnimation(tl, scope) {
  const { section_id } = scope;

  tl.set(
    [`#${section_id}_clipped_main-blob`, `#${section_id}_text`],
    {
      zIndex: 100,
    },
    0
  );

  tl.to(
    `#${section_id}_main-blob`,
    {
      scale: "+=10",
      duration: 1.1,
      transformOrigin: "200px 200px",
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_small-blob`, `#${section_id}_small-blob-behind`],
    {
      duration: 0.5,
      scale: 0,
      ease: "power3.inOut",
    },
    0
  );
  tl.to(
    [`#${section_id}_image_vd`, `#${section_id}_image_vd-behind`],
    {
      duration: 0.5,
      autoAlpha: 0,
      ease: "power3.inOut",
    },
    0
  );
}

export default {
  generateStyles,
  setupHoverAnimation,
  setupTransitionOutAnimation,
};
