import { darken, lighten, getLuminance } from "polished";

export default function hoverColor(color, strength = 1) {
  const lum = getLuminance(color);
  if (lum > 0.12) {
    return darken(0.05 * strength, color);
  } else {
    return lighten(0.15 * strength, color);
  }
}
